"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var Item = _styledComponents["default"].li.withConfig({
  displayName: "Item",
  componentId: "sc-1kqwwe9-0"
})(["margin-top:20px;&:before{font-family:HelveticaNeue;content:'';color:black;font-size:1rem;position:absolute;margin-left:-25px;width:15px;margin-top:3px;height:15px;line-height:1.4;background:white;border-radius:50%;text-align:center;content:counter(li);counter-increment:li;}"]);

var _default = Item;
exports["default"] = _default;