"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ItemList = _styledComponents["default"].ol.withConfig({
  displayName: "ItemList",
  componentId: "sc-o1qht2-0"
})(["text-align:left;list-style:none;counter-reset:li;"]);

var _default = ItemList;
exports["default"] = _default;