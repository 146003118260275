import { combineReducers } from 'redux';
import visit from './visit';
import footer from './footer';
import emailService from './email';

export default combineReducers({
  visit,
  footer,
  emailService
});
