import React, { useEffect, useState, useRef } from 'react';
import {
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initVisit, checkVisit } from 'src/reducers/visit';
import styled, { css } from 'styled-components';
import {
  Color,
  Text,
  Button,
} from 'mac-uikit';
import scrollToComponent from 'react-scroll-to-component';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { showFooter, hideFooter } from 'src/reducers/footer';
import { useTranslation } from 'react-i18next';

import Favourites from 'src/components/Favourites/Favourites';
import QR from 'src/components/QR/QR';
import VTO from 'src/components/VTO/VTO';
import MYOP from 'src/components/MYOP/MYOP';
import Email from 'src/components/Email/Email';

const HomeContainer = styled.div`
display: block;
height: 100%;
width: 100%;
background-color: white;
margin: 0 auto;
text-align: center;
`;

const MyopContainer = styled.div``;

const FavoritesContainer = styled.div`
  margin-top: 50px;
  text-align: center; 
`;

const StickyOverlay = styled.div`

  position: fixed;
  height: 100px;
  width: 100%;
  bottom: 0;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: bottom ease-in-out .5s;
  transform: translateY(100px);
  bottom: 0;
  padding-bottom:0px;

  ${(props) => props.display && css`
    bottom: 100px;
    padding-bottom:10px;
  `}
`;


const StickySpace = styled.div`
  margin-top: 5px;
  height: 20px;
`;

const ExperienceButton = styled(Button)`
  text-transform: uppercase;
  width: 90%;
  margin-top: 10px;
  font-size: 1.5rem;
`;

const QRContainer = styled.div``;
const VTOContainer = styled.div``;
const EmailContainer = styled.div`

background-color: ${Color.black};

`;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function () {
  const qrEl = useRef(null);
  const { visit, footer, emailService } = useSelector((store) => store);
  const { visitId } = useParams();
  const [showSticky, setShowSticky] = useState(false);
  const dispatch = useDispatch();
  const emailRef = useRef(null);
  const { t } = useTranslation();

  const executeScroll = () => {
    scrollToComponent(emailRef.current, {
      offset: 0,
      align: 'top',
      duration: 1000,
    });
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const posY = Math.abs(currPos.y);

      if (emailRef.current == null || qrEl.current == null) {
        return;
      }
      
      if (posY > emailRef.current.offsetTop - emailRef.current.offsetHeight) {
        dispatch(hideFooter());
      } else if (posY > qrEl.current.offsetTop)  {
        dispatch(showFooter());
      } else {
        dispatch(hideFooter());
      }
    },
    [showSticky, dispatch],
  );

  useState(() => {
    dispatch(initVisit(visitId, visit.isSelected));
  }, [visitId]);

  useInterval(() => {
    if (!visit.isLoading && !visit.isChecking) {
      dispatch(checkVisit(visitId, visit.lastUpdated, visit.isSelected));
      console.log(visit)
    }
  }, 3000);

  return (
    <>
      <HomeContainer>
        <QRContainer ref={qrEl}>
          <QR  />
        </QRContainer>
        
        {visit.favorites.length > 0
        && (
        <FavoritesContainer>
          <Favourites />
        </FavoritesContainer>
        )}
        {visit.customPalettes.length > 0
        && (
          <MyopContainer>
            <MYOP palettes={visit.customPalettes} />
          </MyopContainer>
        )}
        {visit.vto.length > 0
         && (
         <VTOContainer>
           <VTO vtos={visit.vto} />
         </VTOContainer>
         )}

        {(visit.favorites.length > 0 || visit.customPalettes.length > 0 || visit.vto.length > 0)
        && (
        <EmailContainer ref={emailRef}>
          <Email />
        </EmailContainer>
        ) }
        {/* <StickySpace /> */}
        <StickyOverlay display={footer.show && !emailService.sendingEmail}>
          <Text color={Color.black} size={0.7} marginTop={10} bold>{t('home.email_you_mac_session')}<br/>{t('home.this_page_will_expire')}</Text>
          <ExperienceButton inverse uppercase onClick={executeScroll}>{t('home.email_you_mac_session_btn')}</ExperienceButton>
        </StickyOverlay>
      </HomeContainer>

    </>
  );
}
