import React from 'react';
import styled from 'styled-components';
import {
  Text,
} from 'mac-uikit';
import favoriteIcon from 'src/assets/images/favoriteIcon.png';
import PaletteProduct from 'src/assets/images/paletteProduct.png';

const ProductContainer = styled.div``;
const ProductImg = styled.img`
width: 100%;

`;
const ProductTitle = styled(Text)``;
const ProductDescription = styled(Text)`
  margin-bottom: 5px;
`;
const FavoriteIcon = styled.img`
  width: 13px;
  height: 15px;
`;

const Product = ({ product }) => (
  <>
    <ProductImg src={product.productImage} />
    <ProductTitle uppercase bold>{product.productName}</ProductTitle>
    <ProductDescription marginTop={5} size={0.8}>{product.shadeName}</ProductDescription>
    <FavoriteIcon src={favoriteIcon} />
  </>
);

export default Product;
