import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Color,
  Text,
  Title,
  EmailInput,
  Button,
  ErrorMessage,
} from 'mac-uikit';
import { useDispatch, useSelector } from 'react-redux';
import { sendingEmail, pendingSendEmail } from 'src/reducers/email';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import FavoriteIcon from 'src/assets/images/favoriteIconWhite.png';
import Palette from 'src/assets/images/paletteWhite.png';
import axios from 'axios';
import api from 'mac-api';
import { getMarket } from 'src/i18n';
import ApplicationInsightsManager from 'src/Analytics/ApplicationInsightManager';

const EmailContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 50px 25px 10px;
  max-width: 400px;
  margin: 95px auto 0;

  h1 {
    line-height: 1.1;
    letter-spacing: -2px;
  }
`;

const CheckboxesContainer = styled.div`
  margin-top: ${(props) => props.marginTop};
  display: flex;
  justify-content: space-around;
`;
const FavoriteCheckboxContainer = styled.div`

`;
const PaletteCheckboxContainer = styled.div`

`;

const TextContainer = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: space-around;
`;

const DisclaimerContainer = styled.div`
  margin-top: 15px;

  ${(props) => props.left && css`
    text-align: left;
    display: flex;
    input {
      height: 10px;
    }
    p{
      margin-left: 10px;
    }
  `}
`;

const InputContainer = styled.div`
  margin-top: 15px;
`;

const EmailInputStyled = styled(EmailInput)`
  margin-top: 21px;
  outline: 0;
  border-width: 0 0 2px;
  border-color: ${Color.white};
  margin-top: 21px;
  width: 100%;
  background-color: ${Color.black};
  color: ${Color.white};
  padding: 0;
`;

const StyledButton = styled(Button)`
  min-height: 50px;
  height: 100%;
  margin-top: 15px;
`;

const Checkbox = styled.input`
  -webkit-appearance: none;
    background-color: ${Color.black};
    border: 2px solid ${Color.white};
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    padding: 9px;
    border-radius: 0px;
    display: inline-block;
    position: relative;

    &:active, &:checked:active {
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }
    
    &:checked {
      background-color: ${Color.black};
      border: 2px solid ${Color.white};
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
      color: ${Color.white};
    }

    &:checked:after {
      content: "${(props) => props && '\\2713'}";
      font-size: 17px;
      position: absolute;
      top: -1px;
      left: 2px;
      color: ${Color.white};
    }
`;

const ConfirmationContainer = styled.div`
    margin-bottom: 50px;
    padding: 0 25px;
`;

const SendingContainer = styled.div`
    height: 150px;
    margin-bottom: 50px;
    padding: 0 25px;
`;

const EmailYourSessionTitle = styled(Title)`
  font-size: 2.5rem;
`;

const EmailMeOptionsText = styled(Text)`
  margin: 0px 10px 0px 10px;
  width: 150px;
`;

const PrivacyPolicyLink = styled.a`
    color: white;
`;

const Email = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [optIn, setOptIn] = useState(false);
  const { visit, emailService } = useSelector((store) => store);
  const [sendFavoritesCheckbox, setSendFavorites] = useState(false);
  const [sendPalettesCheckbox, setSendPalettes] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const sendEmail = () => {

    const checkboxWasShowed = (visit.favorites.length > 0 || visit.vto.length > 0) && visit.customPalettes.length > 0;
    var sendFavorites;
    var sendPalettes;
    
    if (checkboxWasShowed) {
      if (!sendFavoritesCheckbox && !sendPalettesCheckbox) {
      setError(t('email.select_option_error'));
        return;
      }
      sendFavorites = sendFavoritesCheckbox;
      sendPalettes = sendPalettesCheckbox;
    } else {
      if (visit.customPalettes.length > 0) sendPalettes = true;
      else sendFavorites = true;
    }

    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setError(t('email.valid_address_error'));
      return;
    }
    setIsSending(true);
    dispatch(sendingEmail())
    const emailCalls = [];

    if (sendFavorites) {

      const sendFavoritesEmail = api.post('/Visit/sendvisitdetailsemail', {
        visitId: `${visit.id}`,
        EmailAddress: email,
        optIn,
        lng: i18n.language
      });
      emailCalls.push(sendFavoritesEmail);

      ApplicationInsightsManager.shared.trackEvent("SEND_EMAIL", {
        User_email: email,
        Visit_Id: `${visit.id}`,
        Marketing_opt_in: optIn,
        Serial_Number: `${visit.serialNumber}`,
        SAP_Store_Number: `${visit.storeNumber}`,
        Door_Id: `${visit.doorId}`,
        Door_Name: `${visit.doorName}`
       });
    }

    if (sendPalettes && visit.customPalettes.length > 0) {
      visit.customPalettes.map((palette) => {
        const sendPaletteEmail = api.post('/CustomPalette/sendpalettedetailsemail', {
          visitId: `${visit.id}`,
          customPaletteId: palette.customPaletteId,
          EmailAddress: email,
          displayPrice: false,
          optIn,
          lng: i18n.language
        });
        emailCalls.push(sendPaletteEmail);

        ApplicationInsightsManager.shared.trackEvent("SEND_EMAIL", {
          User_email: email,
          Visit_Id: `${visit.id}`,
          Marketing_opt_in: optIn,
          Serial_Number: `${visit.serialNumber}`,
          SAP_Store_Number: `${visit.storeNumber}`,
          Door_Id: `${visit.doorId}`,
          Door_Name: `${visit.doorName}` });
      });
    }
    Promise.all(emailCalls)
      .then(() => {
        setEmailSent(true);
        setTimeout(() => {
          setEmailSent(false);
          setError(null);
          setEmail('');
          setIsSending(false);
          dispatch(pendingSendEmail())
        }, 5000);
      }).catch((error) => {
        setError(t('email.try_again_error'));
        setIsSending(false);
        dispatch(pendingSendEmail())
      });
  };

  function renderOptionsBlock() {
    if  (!emailSent && !isSending) {
      return(
        <div>
        <EmailYourSessionTitle bold color={Color.white}>
          {t('email.email_your')}
          <br />
          {t('email.mac_pass_studio_session')}
          {' '}
        </EmailYourSessionTitle>
        <CheckboxesContainer marginTop="45px">
          {(visit.favorites.length > 0 || visit.vto.length > 0) && (
          <FavoriteCheckboxContainer>
            <img src={FavoriteIcon} width="60" />
          </FavoriteCheckboxContainer>
          ) }
          {visit.customPalettes.length > 0 && (
          <PaletteCheckboxContainer>
            <img src={Palette} width="64" />
          </PaletteCheckboxContainer>
          ) }
        </CheckboxesContainer>
        <TextContainer>
          {(visit.favorites.length > 0 || visit.vto.length > 0)
          && (
          <EmailMeOptionsText color={Color.white}>
            {t('email.email_me')}
            <br/>
            {t('email.my_favourites_and_photos')}
          </EmailMeOptionsText>
          ) }
          {visit.customPalettes.length > 0 && (
          <EmailMeOptionsText color={Color.white}>
            {t('email.email_me')}
            <br/>
            {t('email.my_custom_palettes')}
          </EmailMeOptionsText>
          ) }
        </TextContainer>
        <CheckboxesContainer marginTop="15px">
          {((visit.favorites.length > 0 || visit.vto.length > 0) && visit.customPalettes.length > 0) && <Checkbox type="checkbox" checked={sendFavoritesCheckbox} onChange={(e) => setSendFavorites(!sendFavoritesCheckbox)} /> }
          {((visit.favorites.length > 0 || visit.vto.length > 0) && visit.customPalettes.length > 0) && <Checkbox type="checkbox" checked={sendPalettesCheckbox} onChange={(e) => setSendPalettes(!sendPalettesCheckbox)} /> }
  
        </CheckboxesContainer>
        <DisclaimerContainer>
          <Text color={Color.white} size={0.7}>
            {t('email.please_enter_address_1')} <PrivacyPolicyLink href={t('privacy_policy_link')}>{t('email.privacy_policy')}</PrivacyPolicyLink>{t('email.please_enter_address_2')}
          </Text>
        </DisclaimerContainer>
        </div>
      );
    } else if (!emailSent && isSending) {
      return(
        <SendingContainer>
        <Title fontSize={3} bold color={Color.white}>
          {t('email.sending_your')}
          <br />
          {t('email.mac_pass_studio')}
          {' '}
        </Title>
        </SendingContainer>
      );
    } else {
      return(<div></div>);
    }
  }

  return (
    
    <EmailContainer>
      {renderOptionsBlock()}
      {!isSending
      && (
      <>
        {' '}
        <InputContainer>
          <EmailInputStyled type="email" placeholder={t('email.your_email')} onChange={(e) => setEmail(e.target.value)} />
        </InputContainer>
        { error && <ErrorMessage size={1.31} marginTop={15}>{error}</ErrorMessage>}
        {getMarket(i18n.language) != 'GB' && 
          getMarket(i18n.language) != 'FR' && 
          getMarket(i18n.language) != 'DE' &&
          getMarket(i18n.language) != 'JP' &&
          getMarket(i18n.language) != 'KR' && (
          <DisclaimerContainer left>
            <Checkbox type="checkbox" checked={optIn} onChange={(e) => setOptIn(!optIn)} />
            <Text color={Color.white} size={0.5}>
            {t('email.sign_me_up_1')} <PrivacyPolicyLink href={t('privacy_policy_link')}>{t('email.privacy_policy')}</PrivacyPolicyLink>{t('email.sign_me_up_2')}
              {' '}
            </Text>
          </DisclaimerContainer>
        )}

        {!isSending && <StyledButton inverted onClick={sendEmail}> {t('email.send_email')} </StyledButton> }
      </>
      )}

      {emailSent && (
      <SendingContainer>
        <Title fontSize={3} bold color={Color.white} marginTop={25}>
          {t('email.your_requested')}<br/>{t('email.email_has_been_sent')}
        </Title>
        <Text color={Color.white} marginTop={25}>{t('email.check_your_inbox')}</Text>
      </SendingContainer>
      )}

    </EmailContainer>
  );
};

export default Email;
