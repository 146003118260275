import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Title,
  Color,
  Text,
} from 'mac-uikit';
import { useDispatch, useSelector } from 'react-redux';
import { EXPERIENCES_CONFIG, selectCategory } from 'src/reducers/visit';
import { useTranslation } from 'react-i18next';

import ProductList from 'src/components/ProductList/ProductList';

import lips from 'src/assets/images/lips.png';
import eyes from 'src/assets/images/eyes.png';
import face from 'src/assets/images/face.png';
import studio from 'src/assets/images/studio.png';

import PaletteProduct from 'src/assets/images/paletteProduct.png';

const TabsContainer = styled.div`
  tex-align: center;
  margin: 25px auto 0;
  width: 90%;
  max-width: 415px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
`;

const SelectorContainer = styled.div`
  border-radius: 50%;
  padding: 2px;
  margin: 0 auto;
  box-sizing: border-box;
  height: 53px;
  width: 53px;
  ${({ isSelected }) => isSelected && `
    border: 2px solid ${Color.black};
    margin-top: -2px;
  `}
`;

const Selector = styled.img`
  width: 45px;
  height: 45px;
  margin: auto;
`;

const ExperienceContainer = styled.div`
box-sizing: border-box;
`;

const ExperienceCount = styled.span`
  ${({ isSelected }) => !isSelected && `
    color: ${Color.lightGrey}
  `}
`;

const ExperienceText = styled(Text)`
  height: 10px;
  ${({ hasItems }) => !hasItems && `
    color: ${Color.lightGrey};
  `}

  ${({ isSelected }) => isSelected && `
    border-bottom: 2px solid black;
    padding-bottom: 5px;
    margin-top: 2px;
  `}

  width: fit-content;
  margin-left: auto;
  margin-right: auto;

`;

const CheckMark = styled.div`
  border-radius: 50%;
  background-color: ${Color.green};
  height: 15px;
  width: 15px;
  align-self: flex-end;
  position: absolute;
  margin-top: 1px;
  margin-left: -4px;
  color: ${Color.white};
  font-size: 10px;
  line-height: 1.5;
  font-weight: bold;
  margin-left: 32px;
`;

const FavesDescription = styled.span`
  font-weight: bold;
`;

const Favourites = () => {
  // TODO: Integrate this to redux
  const { visit } = useSelector((store) => store);
  const [experiences, setExperiences] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const lipsItems = visit.favorites.filter((product) => product.experience == EXPERIENCES_CONFIG.LIP);
    const eyesItems = visit.favorites.filter((product) => product.experience == EXPERIENCES_CONFIG.EYE);
    const faceItems = visit.favorites.filter((product) => product.experience == EXPERIENCES_CONFIG.FACE);
    const artItems = visit.favorites.filter((product) => product.experience == EXPERIENCES_CONFIG.ART_STUDIO);
    setExperiences([{
      name: t('favourites.lips'),
      items: lipsItems,
      img: lips,
      type: EXPERIENCES_CONFIG.LIP,
    },
    {
      name: t('favourites.eyes'),
      items: eyesItems,
      img: eyes,
      type: EXPERIENCES_CONFIG.EYE,
    },
    {
      name: t('favourites.face'),
      items: faceItems,
      img: face,
      type: EXPERIENCES_CONFIG.FACE,
    },
    {
      name: t('favourites.art_studio'),
      items: artItems,
      img: studio,
      type: EXPERIENCES_CONFIG.ART_STUDIO,
    }]);

  }, [visit]);
  const showExperience = (experienceToUpdate) => {
    if (experienceToUpdate.items.length === 0) {
      return;
    }
    dispatch(selectCategory(experienceToUpdate.type));
  };

  return (
    <>
      <Title color={Color.black} fontSize={4}>{t('favourites.my_favourites')}</Title>
      <Text marginTop={15}>
        <FavesDescription>
          {' '}
          {t('favourites.products_you_liked')} (
          {visit.favorites.length}
          )
        </FavesDescription>
      </Text>
      <TabsContainer>
        { experiences.map((experience) => (

          <ExperienceContainer onClick={() => showExperience(experience)}>
            {visit.experiencesStatus[experience.type] && <CheckMark>✓</CheckMark>}
            <SelectorContainer isSelected={visit.isSelected == experience.type}>
              <Selector src={experience.img} />
            </SelectorContainer>
            <ExperienceText size={0.5} uppercase bold marginTop={0} isSelected={visit.isSelected == experience.type} hasItems={experience.items.length}>
              {experience.name}
              {' '}
              <ExperienceCount isSelected={visit.isSelected == experience.type}>
                (
                {experience.items.length}
                )
              </ExperienceCount>
            </ExperienceText>
          </ExperienceContainer>
        ))}
      </TabsContainer>
      { experiences.map((experience) => (
        <ProductList experience={experience} isSelected={visit.isSelected == experience.type} />
      ))}
    </>
  );
};

export default Favourites;
