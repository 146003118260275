import React from 'react';
import {
  Title,
  PaletteList,
  Color,
  Text,
} from 'mac-uikit';
import styled from 'styled-components';
import base64 from 'src/utils/index';
import { withTranslation } from 'react-i18next';

const TitleContainer = styled.div`
  margin-top: 50px;
  background-color: black;
  padding:50px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
`;

const YourCustomPalettesTitle = styled(Title)`
  font-size: 4rem;
`;


const PaletteDescriptionContainer = styled.div`
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 0 15px;
`;

const Img = styled.img`
  margin: 25px 0;
  width 100%;
  max-width: 330px;
`;

const MYOP = ({ palettes, t }) => (
  <>
    <TitleContainer>
      <YourCustomPalettesTitle padding={50} bold color={Color.white}>{t('myop.your_custom_palettes')} </YourCustomPalettesTitle>
      <Text fontSize={1.4} marginTop={20} color={Color.white}>
        {t('myop.your_custom_palettes')} (
        {palettes.length}
        )
      </Text>
    </TitleContainer>

    {palettes.map((palette) => (
      <>
        <Img src={base64(palette.customPaletteImageBase64, palette.customPaletteImageExtension)} />
        <PaletteDescriptionContainer>
          <Text bold uppercase>{palette.paletteContainerName}</Text>
        </PaletteDescriptionContainer>
        <PaletteList shades={palette.paletteShadeProducts} />
      </>
    ))}
  </>
);

export default withTranslation()(MYOP);
