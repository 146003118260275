// configureStore.js
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage/session';

import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
};


export default () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: false,
    }),
  });
  return { store };
};
