
import {
  SHOW_FOOTER,
  HIDE_FOOTER
} from './actionTypes';

const defaultState = {
  show: false,
};

export const showFooter = () => (dispatch) => dispatch({ type: SHOW_FOOTER });

export const hideFooter = () => (dispatch) => dispatch({ type: HIDE_FOOTER });

const footer = (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_FOOTER:
      return {
        show: true,
      };
    case HIDE_FOOTER:
      return {
        show: false,
      };
    default:
      return state;
  }
};

export default footer;
