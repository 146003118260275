import React from 'react';
import {
  Title,
  Color,
  Text,
} from 'mac-uikit';
import styled, { css } from 'styled-components';
import base64 from 'src/utils/index';

import Model from 'src/assets/images/model.png';
import ProductList from 'src/components/ProductList/ProductList';
import { EXPERIENCES_CONFIG, selectCategory } from 'src/reducers/visit';
import { withTranslation } from 'react-i18next';

import eyeImg from 'src/assets/images/eye.png';
import PaletteProduct from 'src/assets/images/paletteProduct.png';

const TitleContainer = styled.div`
  margin-top: 50px;
  background-color: black;
  padding:50px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  min-height: 555px;
`;

const LooksContainer = styled.div`
margin-top: 50px;
  ${(props) => props.isFirst && css`
  margin-top: -277px

  `}
`;
const Look = styled.div``;
const LookImage = styled.img`
  max-width: 407px;
  width: 90%;
`;
const LookHeader = styled.div``;
const LooksCount = styled.span`
  font-weight: bold;
`;

const ArtistStudioLookVideoContainer = styled.div`
  margin: 25px auto 0;
  height: auto;
  width: 80%;
  max-width: 300px;
  display: ${({ isArtistStudioItem }) => isArtistStudioItem ? '' : 'none' };
`;

const VideoThumbnailContainer = styled.div`
  position: relative;
`;

const VideoThumbnail = styled.img`
  width: 100%;
  height: 70%;
  object-fit: cover;
`;

const VideoButton = styled.div`
  margin: 5% 5% 0 4%;
  width: 90%;
  height: 50px;
  border: 2px solid black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: TradeGothicBold;
  font-size: 1em;
  color: black;

  :active {
    background-color: black;
    color: white;
  }
`;


const PlayIcon = styled.img`
    width: 10px;
    height: 14px;
`;

const VideoPlayIcon = styled.img`
    position: absolute;
    width: 10px;
    height: 14px;
    z-index: 2;
    bottom: 10px;
    left: 10px;
`;

const ButtonCopy = styled.div`

`;

const LookTitle = styled(Title)`
    line-height: 1.2;
`;

// const ArtistStudioLookVideoContainer = styled.div`
//   margin: 10%;
//   height: 260px;
//   width: 80%;
//   background-color: red;
//   display: ${({ isArtistStudioItem }) => isArtistStudioItem ? '' : 'hidden' };
// `;

const VTO = ({ vtos, t }) => (
  <>
    <TitleContainer>
      <LookTitle fontSize={5} padding={50} bold color={Color.white}>
        {t('vto.looks_you')}
        <br/>
        {t('vto.snapped')}
        {' '}
      </LookTitle>
      <Text marginTop={50} color={Color.white}>
        {t('vto.album')}
        <LooksCount>
          {' '}
          {t('vto.photos_and_gifs')} (
          {vtos.length}
          )
        </LooksCount>
      </Text>
    </TitleContainer>
    {vtos.map((vto, index) => (
      <>
        <LooksContainer isFirst={index == 0}>
          <Look>
            <LookImage src={base64(vto.vtoImageContent, vto.vtoImageExtension)} />
          </Look>
          <LookHeader>
            <Text size={1.3} marginTop={20} bold color={Color.black} uppercase>{vto.lookName}</Text>
            <Text size={1} marginTop={6} color={Color.black}>{t('vto.how_to_get_the_look')}</Text>
          </LookHeader>
        </LooksContainer>
        <ProductList experience={{ items: vto.products }} isSelected={true} />
        <ArtistStudioLookVideoContainer isArtistStudioItem={ vto.youtubeVideoUrl && vto.youtubeThumbnailUrl  }>
          <VideoThumbnailContainer>
            <VideoThumbnail src={vto.youtubeThumbnailUrl}> 
            </VideoThumbnail>
            <VideoPlayIcon src="/video-icons/play-white@2x.png"/>
          </VideoThumbnailContainer>
          <VideoButton onClick={ () => window.open(vto.youtubeVideoUrl, "_blank")}>
            <PlayIcon src="/video-icons/play@2x.png" />
            <ButtonCopy>
            {t('vto.watch_tutorial_on_youtube')}
            </ButtonCopy>
          </VideoButton>
        </ArtistStudioLookVideoContainer>
      </>
    ))}

  </>
);

export default withTranslation()(VTO);
