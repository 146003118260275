
import {
    IS_SENDING_EMAIL,
    PENDING_SEND_EMAIL
  } from './actionTypes';
  
  const defaultState = {
    sendingEmail: false
  };
  
  export const sendingEmail = () => (dispatch) => dispatch({ type: IS_SENDING_EMAIL });
  
  export const pendingSendEmail = () => (dispatch) => dispatch({ type: PENDING_SEND_EMAIL });
  
  const emailService = (state = defaultState, action) => {
    switch (action.type) {
      case IS_SENDING_EMAIL:
        return {
          sendingEmail: true,
        };
      case PENDING_SEND_EMAIL:
        return {
            sendingEmail: false
        };
      default:
        return state;
    }
  };
  
  export default emailService;
  