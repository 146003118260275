"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _axios = _interopRequireDefault(require("axios"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var apiUrl = process && process.env && process.env.API_URL || "https://projred-public-api.elcompanies.com/api";

var instance = _axios["default"].create({
  baseURL: apiUrl,
  timeout: 30000,
  headers: {
    'MacRedApiKey': process && process.env && process.env.API_KEY || "{6AC426DD-4782-4395-8C17-77DDC7620994}"
  }
});

var _default = instance;
exports["default"] = _default;