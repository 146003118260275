import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import MacLogo from 'src/assets/images/maclogocheckin.png';
import MacQR from 'src/assets/images/mac-qr.png';
import QRLogo from 'src/assets/images/wideQR.png';
import { useTranslation } from 'react-i18next';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Bar from 'src/assets/images/bar.png';

import { showFooter, hideFooter } from 'src/reducers/footer';
import * as QRCode from 'easyqrcodejs';

import {
  Title,
  Text,
  Color,
} from 'mac-uikit';
import { element } from 'prop-types';

const Container = styled.div`
  height: 100vh;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const LogoContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 23px;
`;

const Img = styled.img`
  width: 100%;
  max-width: 114px;
  height: auto;
`;

const HeadlineContainer = styled.div`
  margin-top: 35px;
`;

const CodeContainer = styled.div`
height: 100%;
max-width: 300px;
width: 90%;
margin: 0 auto;
box-sizing: border-box; 
display: flex;
flex-direction: column;

`;
const QRContainer = styled.div`
  background-color: black;
  width: 100%;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-box-shadow: 0px 0px 32px -2px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 32px -2px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 32px -2px rgba(0,0,0,0.5);
  padding: 50px 20px 30px;
  text-align: center;
  box-sizing: border-box; 

  img {
    width: 100%;
   
    @media only screen and (max-width: 320px) {
      max-height: 150px;
      max-width: 150px;
    }
  }


  ${({ noRadius }) => noRadius && `

    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: translateY(-5px);
    margin: 0 auto 0;
    flex-grow: 2;
    display: flex;
    padding-top: 25px;
    flex-direction: column;
    align-items: center;
  `}
`;

const TopContainer = styled.div`
  margin-bottom: 50px;
`;

const QRImageContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  div {
    background-color:white;
    padding: 20px;
    margin-bottom: 70px;
     @media only screen and (max-width: 320px) {
      margin-bottom: 0;

    }
  }
  `;

const StickyHeader = styled.div`

  
  position: fixed;
  z-index: 9999;
  top: -100px;
  height: 72px;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
 
  flex-direction: column;
  transition: top ease-in-out .5s;

  ${({ showSticky }) => showSticky && `
    display: flex;
    top: 0;
   
  `
}
`;

const StickyImageContainer = styled.div`
  img {
    height: 57px;
    width: 57px; 
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const BarContainer = styled.div`
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;

const StickyBar = styled.div`
  background-color: black;
  height: 40px;
  width: 100%;
 

  ${({ bottom }) => bottom && `
    margin-top: -4px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  `}
`;

const ScrollContainer = styled.div`


`;

const WelcomeTitle = styled(Title)`
  line-height: 1.2;
  margin: 0px 5% 0px 5%;
`;

const Arrow = styled.i`
  border: solid ${Color.white};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3.5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;

const QR = () => {
  const qrEl = useRef(null);
  const QRRef = useRef(null);
  const [qrProcess, setQrProcess] = useState(false);
  const [showSticky, setShowSticky] = useState(false);
  const dispatch = useDispatch();
  const { visit } = useSelector((store) => store);
  const { t } = useTranslation();

  useEffect(() => {
    if (!qrProcess) {
      const optionsObject = {
        // ====== Basic
        text: window.location.href,
        width: 256,
        height: 256,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H, // L, M, Q, H
        dotScale: 1, // Must be greater than 0, less than or equal to 1. default is 1
        logo: QRLogo, // Relative address, relative to `easy.qrcode.min.js`
        logoWidth: 120, // widht. default is automatic width
        logoHeight: 60, // height. default is automatic height
        logoBackgroundColor: '#fffff', // Logo backgroud color, Invalid when `logBgTransparent` is true; default is '#ffffff'
        logoBackgroundTransparent: true, // Whether use transparent image, default is false
        quietZone: 1,
        quietZoneColor: 'white',

      };

      const qrcode2 = new QRCode(QRRef.current, optionsObject);
      setQrProcess(true);
    }
  }, [visit]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const posY = Math.abs(currPos.y);
      if (posY > qrEl.current.offsetTop + qrEl.current.offsetHeight - 90)  {
        setShowSticky(true);
      } else {
        setShowSticky(false);
      }
    },
    [showSticky, dispatch],
  );
  return (
    <>
      <Container ref={qrEl}>
        <TopContainer>
          <LogoContainer>
            <Img src={MacLogo} />
          </LogoContainer>
          <HeadlineContainer>
            <WelcomeTitle fontSize={3.43}>{t('qr.welcome')}</WelcomeTitle>
            <Title fontSize={4.31} marginTop={5}>{t('qr.mac_pass_studio')}</Title>
          </HeadlineContainer>
        </TopContainer>
        <CodeContainer>
          <QRContainer>
            <Text color={Color.white} bold size={1}>
            <p>{t('qr.keep_this_mac_pass')}</p>
            </Text>
          </QRContainer>
          <BarContainer>
            <img src={Bar} />
          </BarContainer>
          <QRContainer noRadius>
            <QRImageContainer>
              <div ref={QRRef} />
            </QRImageContainer>
            <ScrollContainer>
              <Text bold size={0.8} color={Color.white}>
                {t('qr.scroll_for_your_favourites')}
              </Text>
              <Arrow />
            </ScrollContainer>
          </QRContainer>

        </CodeContainer>

      </Container>
      <StickyHeader showSticky={showSticky}>
        <StickyBar />
        <BarContainer>
          <img src={Bar} />
        </BarContainer>
        <StickyBar bottom />

        <StickyImageContainer>
          <img src={MacQR} />
        </StickyImageContainer>
      </StickyHeader>
    </>
  );
};

export default QR;
