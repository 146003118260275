
import React from 'react';
import styled from 'styled-components';

import Product from 'src/components/ProductList/Product';

const ProductsListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  max-width: 300px;
  margin: 25px auto 0;
  ${({ showList }) => !showList && 'display: none;'}
`;

const ProductContainer = styled.div`
padding: 15px 0;
`;


const ProductList = ({ experience, isSelected }) => (
  <ProductsListContainer showList={isSelected}>
    { experience.items.map((product) => (
      <ProductContainer>
        <Product product={product} />
      </ProductContainer>
    ))}
  </ProductsListContainer>
);

export default ProductList;
