"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var Title = _styledComponents["default"].h1.withConfig({
  displayName: "Title",
  componentId: "sc-hkudb7-0"
})(["color:", ";font-family:HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:", "rem;margin:0;text-align:center;", " margin-right:", ";", " ", ""], function (props) {
  return props.color;
}, function (props) {
  return props.fontSize;
}, function (_ref) {
  var hasPaddingTop = _ref.hasPaddingTop;
  return hasPaddingTop && "\n    padding-top: 7.5vh;\n  ";
}, function (props) {
  return props.hasRightMargin + 'px' || "0";
}, function (_ref2) {
  var marginTop = _ref2.marginTop;
  return marginTop && "\n    margin-top: ".concat(marginTop, "px;\n  ");
}, function (_ref3) {
  var hasPaddingTop = _ref3.hasPaddingTop;
  return !hasPaddingTop && 'line-height: 0.8;';
});

var _default = Title;
exports["default"] = _default;