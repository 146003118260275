export const INIT_VISIT = 'INIT_VISIT';
export const START_EMAIL = 'START_EMAIL';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const SHOW_FOOTER = 'SHOW_FOOTER';
export const HIDE_FOOTER = 'HIDE_FOOTER';
export const IS_LOADING = 'IS_LOADING';
export const CHECK_VISIT = 'CHECK_VISIT';
export const IS_CHECKING = 'IS_CHECKING';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const PENDING_SEND_EMAIL = 'PENDING_SEND_EMAIL';
export const IS_SENDING_EMAIL = 'IS_SENDING_EMAIL';

export default {
  INIT_VISIT,
  START_EMAIL,
  CONFIRM_EMAIL,
  SHOW_FOOTER,
  HIDE_FOOTER,
  CHECK_VISIT,
  IS_CHECKING,
  SELECT_CATEGORY,
  IS_SENDING_EMAIL,
  PENDING_SEND_EMAIL
};
