"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var TextBox = _styledComponents["default"].div.withConfig({
  displayName: "TextBox",
  componentId: "sc-16cm7a1-0"
})(["border:1px solid black;padding:20px;text-align:center;max-width:600px;margin:auto;"]);

var _default = TextBox;
exports["default"] = _default;