import api from 'mac-api';
import moment from 'moment';
import { useTranslation as t } from 'react-i18next';

import {
  INIT_VISIT,
  START_EMAIL,
  CONFIRM_EMAIL,
  IS_LOADING,
  CHECK_VISIT,
  IS_CHECKING,
  SELECT_CATEGORY
} from './actionTypes';

export const EXPERIENCES_CONFIG = {
  LIP: 'lip',
  EYE: 'eye',
  FACE: 'face',
  ART_STUDIO: 'artStudio',
};

const PALETTES_DIR = process.env.REACT_APP_CMS_URL + '/sites/default/files/images/product';

const defaultState = {
  id: null,
  doorId: null,
  doorName: null,
  serialNumber: null,
  storeNumber: null,
  favorites: [],
  vto: [],
  customPalettes: [],
  experiencesStatus: [],
  isLoading: false,
  lastUpdated: moment(),
  isChecking: false,
  isSelected: null,
};

const formatLooks = ({ productVtoMedia, lookVtoMedia }) => {
  const vto = [];
  productVtoMedia.forEach((product) => {
    vto.push({
      vtoImageContent: product.vtoImageContent,
      vtoImageExtension: product.vtoImageExtension,
      lookName: product.shadeName,
      products: [{
        productCode: product.productCode,
        productImage: product.productImage,
        productName: product.productName,
        shadeName: product.shadeName,
      }],
    });
  });
  lookVtoMedia.forEach((look) => {
    vto.push({
      vtoImageContent: look.vtoImageContent,
      vtoImageExtension: look.vtoImageExtension,
      lookName: look.lookName,
      products: look.products,
      youtubeVideoUrl: look.youtubeVideoUrl,
      youtubeThumbnailUrl: look.youtubeThumbnailUrl,
    });
  });
  return vto;
};

const getExperience = (experience) => {
  switch (experience) {
    case 'boldLip':
      return EXPERIENCES_CONFIG.LIP;
    case 'neutralLip':
      return EXPERIENCES_CONFIG.LIP;
    case 'genericLips':
      return EXPERIENCES_CONFIG.LIP;
    case 'shadeFinder':
      return EXPERIENCES_CONFIG.FACE;
    case 'eye':
      return EXPERIENCES_CONFIG.EYE;
    case 'makeYourOwnPalette':
      return EXPERIENCES_CONFIG.EYE;
    case 'mascara':
      return EXPERIENCES_CONFIG.EYE;
    case 'artistStudio':
      return EXPERIENCES_CONFIG.ART_STUDIO;
    default:
      return null;
  }
};
const getPaletteImage = (paletteId) => `${PALETTES_DIR}/${paletteId}.png`;

const formatFavorites = ((favorites) => {
  const products = [];
  favorites.products.map((product) => {
    products.push({
      productName: product.productName,
      shadeName: product.shadeName,
      productImage: product.productImage,
      experience: getExperience(product.experience),
    });
  });
  favorites.inspirationPalettes.map((palette) => {
    products.push({
      productName: palette.inspirationPaletteName,
      shadeName: palette.inspirationPaletteArtistName,
      productImage: getPaletteImage(palette.inspirationPaletteId),
      experience: getExperience(palette.experience),
    });
  });
  return products;
});


export const initVisit = (visitId, isSelected) => (dispatch) => {
  dispatch({
    type: IS_LOADING,
    payload: true,
  });

  return api.get(`/Visit/visitdetails?visitId=${visitId}`)
    .then((json) => {
      const doorId = json.data.doorId;
      const doorName = json.data.doorName;
      const serialNumber = json.data.serialNumber;
      const storeNumber = json.data.storeNumber;
      const { customPalettes } = json.data;
      const vto = formatLooks(json.data.vtoMedia);
      const favorites = formatFavorites(json.data.favorites);
      const { completedExperiences } = json.data;
      const experiencesStatus = {
        [EXPERIENCES_CONFIG.LIP]: false,
        [EXPERIENCES_CONFIG.EYE]: false,
        [EXPERIENCES_CONFIG.FACE]: false,
        [EXPERIENCES_CONFIG.ART_STUDIO]: false,
      };
      completedExperiences.forEach((experience) => {
        if (experience.type === 'Lips' && experience.completed) {
          experiencesStatus[EXPERIENCES_CONFIG.LIP] = true;
        }
        if (experience.type === 'Eyes' && experience.completed) {
          experiencesStatus[EXPERIENCES_CONFIG.EYE] = true;
        }
        if (experience.type === 'Face' && experience.completed) {
          experiencesStatus[EXPERIENCES_CONFIG.FACE] = true;
        }
        if (experience.type === 'Studio' && experience.completed) {
          experiencesStatus[EXPERIENCES_CONFIG.ART_STUDIO] = true;
        }
      });
      const action = {
        type: INIT_VISIT,
        payload: {
          id: visitId,
          doorId,
          doorName,
          serialNumber,
          storeNumber,
          customPalettes,
          vto,
          favorites,
          experiencesStatus,
          lastUpdated: moment(json.data.lastUpdated),
        },
      };
      dispatch(action);
      dispatch({
        type: IS_LOADING,
        payload: false,
      });
      if(!isSelected) {
        const lipsItems = favorites.filter((product) => product.experience == EXPERIENCES_CONFIG.LIP);
        const eyesItems = favorites.filter((product) => product.experience == EXPERIENCES_CONFIG.EYE);
        const faceItems = favorites.filter((product) => product.experience == EXPERIENCES_CONFIG.FACE);
        const artItems = favorites.filter((product) => product.experience == EXPERIENCES_CONFIG.ART_STUDIO);
        let defaultExperience = null;
        if(lipsItems.length && defaultExperience == null) {
          defaultExperience = EXPERIENCES_CONFIG.LIP;
        }
        if(eyesItems.length && defaultExperience == null) {
          defaultExperience = EXPERIENCES_CONFIG.EYE;
        }
        if(faceItems.length && defaultExperience == null) {
          defaultExperience = EXPERIENCES_CONFIG.FACE;
        }
        if(artItems.length && defaultExperience == null) {
          defaultExperience = EXPERIENCES_CONFIG.ART_STUDIO;
        }
        dispatch({
          type: SELECT_CATEGORY,
          payload: defaultExperience,
        });
      }
     
    }).catch((error) => {
      console.log(error);
      dispatch({
        type: IS_LOADING,
        payload: false,
      });
    });
};

export const checkVisit = (visitId, prevLastUpdated, isSelected) => (dispatch) => {
  dispatch({
    type: IS_CHECKING,
    payload: true,
  });

  api.get(`/Visit/lastupdated?visitId=${visitId}`)
    .then((json) => {
      const { data } = json;
      const lastUpdated = moment(data.lastUpdated);
      if (lastUpdated.isAfter(prevLastUpdated)) {
        const action = {
          type: CHECK_VISIT,
          payload: lastUpdated,
        };
        dispatch(action);
        dispatch(initVisit(visitId, isSelected));
      }
      dispatch({
        type: IS_CHECKING,
        payload: false,
      });
    }).catch((error) => {
      console.log(error)
      dispatch({
        type: IS_CHECKING,
        payload: false,
      });
    });
};

export const selectCategory = (category) => (dispatch) => {

  dispatch({
    type: SELECT_CATEGORY,
    payload: category,
  });
}

export const sendEmail = (customPaletteId, email) => (dispatch) => {
  dispatch({ type: START_EMAIL });
  return api.post('/CustomPalette/sendpalettedetailsemail', {
    customPaletteId: `${customPaletteId}`,
    emailAddress: email,
  })
    .then((json) => {
      const action = {
        type: CONFIRM_EMAIL,
        payload: {
          confirmed: true,
          loading: false,
        },
      };
      return dispatch(action);
    }).catch((error) => {
      const action = {
        type: CONFIRM_EMAIL,
        payload: {
          confirmed: false,
          loading: false,
        },
      };
      dispatch(action);
      if (error.response && error.response.data) {
        const formatError = error.response.data.errors.reduce((message, errorMessage) => `${errorMessage}, ${message}`);
        throw new Error(formatError);
      } else if (error.message) {
        throw new Error(error.message);
      } else {
        throw new Error(t('visit.internal_error'));
      }
    });
};


const visit = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_VISIT:
      return {
        ...state,
        ...action.payload,
      };
    case START_EMAIL:
      return {
        ...state,
        loading: true,
      };
    case CONFIRM_EMAIL:
      return {
        ...state,
        ...action.payload,
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
      case IS_CHECKING:
        return {
          ...state,
          isChecking: action.payload,
        };
    case CHECK_VISIT:
      return {
        ...state,
        lastUpdated: action.payload,
      }
    case SELECT_CATEGORY:
      return {
        ...state,
        isSelected: action.payload,
      }
    default:
      return state;
  }
};

export default visit;
