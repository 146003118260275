import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import './i18n';

import configureStore from './configureStore';

const { store } = configureStore();

ReactDOM.render(<Provider store={store}><Suspense fallback=""><App /></Suspense></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
