"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Color = _interopRequireDefault(require("./Color"));

var _P = _interopRequireDefault(require("./P"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ErrorMessage = (0, _styledComponents["default"])(_P["default"]).withConfig({
  displayName: "ErrorMessage",
  componentId: "sc-1g67nzc-0"
})(["color:", " !important;text-align:left;"], _Color["default"].red);
var _default = ErrorMessage;
exports["default"] = _default;