"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Color = _interopRequireDefault(require("./Color"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var TopAlert = _styledComponents["default"].div.withConfig({
  displayName: "TopAlert",
  componentId: "sc-1c7ti8c-0"
})(["font-family:HelveticaNeue;font-size:", "em;box-sizing:border-box;background-color:", ";width:100%;color:", ";margin:0 0 26px;padding:14px 10%;text-align:center"], function (props) {
  return props.size;
}, _Color["default"].black, _Color["default"].white);

var _default = TopAlert;
exports["default"] = _default;