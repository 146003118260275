"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var Label = _styledComponents["default"].label.withConfig({
  displayName: "Label",
  componentId: "sc-1svpj41-0"
})(["color:", ";font-family:HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:", ";"], function (props) {
  return props.color || '#6d6d6d';
}, function (props) {
  return props.size + 'rem' || "1rem";
});

var _default = Label;
exports["default"] = _default;