"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _Color = _interopRequireDefault(require("./Color"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var Button = _styledComponents["default"].button.withConfig({
  displayName: "Button",
  componentId: "sc-1pm6f2h-0"
})(["margin-bottom:2vh;text-transform:uppercase;background-color:black;color:", ";width:100%;height:9vh;border:1px solid white;font-family:\"TradeGothicBold\";font-size:1.6rem;min-height:60px;text-align:", ";", " ", " ", " ", " transition:opacity 0.2s ease-in-out;"], function (props) {
  return props.color || "white";
}, function (props) {
  return props.textPosition || "center";
}, function (props) {
  return props.absolute && (0, _styledComponents.css)(["position:absolute;bottom:99px;"]);
}, function (props) {
  return props.isLink && (0, _styledComponents.css)(["background-color:unset;border:none;text-transform:initial;text-decoration:underline;height:2rem;min-height:2rem;"]);
}, function (props) {
  return props.inverse && (0, _styledComponents.css)(["background-color:white;text-transform:initial;width:100%;height:52px;min-height:2rem;color:", ";border:1px solid black;line-height:3;font-family:\"TradeGothicBold\";"], _Color["default"].black);
}, function (props) {
  return props.enabled && (0, _styledComponents.css)(["pointer-events:none;opacity:0.5;"]);
});

var _default = Button;
exports["default"] = _default;