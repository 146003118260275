import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import {
  Color,
} from 'mac-uikit';
import Home from 'src/pages/Home/Home';
import './App.scss';
import styled from 'styled-components';
import ApplicationInsightsManager from 'src/Analytics/ApplicationInsightManager';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: ${Color.black};
`;

export default function BasicExample() {

  function setupAnalytics() {
    // console.log("SETUP ANALYTICS");
    ApplicationInsightsManager.shared.load();
  }

  useEffect(() => {
    // Setup Analytics
    setupAnalytics()
  }, [])

  return (
    <Router>
      <Container>
        <Switch>
          <Route path="/:visitId?">
            <Home />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
}
